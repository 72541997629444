<template>
  <div class="text-center" v-if="resolutionScreen < 500">
    <v-snackbar v-model="shower" :timeout="-1" bottom>
      <div
        class="text-right mb-n2 mt-1"
        v-if="countdeleteerror > 0 || countcancel > 0"
      >
        <v-btn @click="$emit('closedialog')" icon x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card color="transparent" elevation="0" class="mt-3 pa-3 " width="320">
        <p v-if="statuscleartrash === 'start'">
          <span class="pl-2">{{ $t("trashPage.preparedelete") }}</span>
          <v-btn
            style="margin-left:10px; margin-top:-4px;"
            class="float-right"
            small
            color="secondary"
            @click="$emit('cancelselectall')"
          >
            {{ $t("dragdrop.cancel") }}
          </v-btn>
        </p>
        <p v-else-if="statuscleartrash === 'cancelselect'">
          <span class="pl-2">{{ $t("trashPage.canceldelete") }}</span>
        </p>
        <p
          v-else-if="
            statuscleartrash === 'delete' &&
              calpercendelete === 100 &&
              countdeleteerror === 0
          "
        >
          {{ $t("trashPage.deletesuccess") }}
          <span class="float-right"
            ><v-icon color="green">mdi-check-bold</v-icon></span
          >
        </p>
        <p v-else-if="statuscleartrash === 'delete' && calpercendelete >= 0">
          <span class="pl-2" v-if="calpercendelete !== 100">{{
            $t("trashPage.deletingfilefolder")
          }}</span>
          <v-btn
            v-if="countdeleteerror > 0"
            style="margin-top:-4px;"
            small
            text
            @click="$emit('detaildelete')"
          >
            {{ $t("trashPage.cannotdelete") }}&nbsp; {{ countdeleteerror }}
            {{ $t("dragdrop.file") }}
          </v-btn>
          <span class="float-right"
            >{{ countdelete }} / {{ filedata.length }} ({{
              calpercendelete
            }}%)</span
          >
          <v-card-text class="text-right" v-if="calpercendelete !== 100">
            {{ showdatadelete[countdelete - 1].file_name }}
            <v-btn
              style="margin-left:10px; margin-top:-4px;"
              small
              color="secondary"
              class="mr-n5"
              @click="$emit('cancelbackupanddelete')"
            >
              {{ $t("dragdrop.cancel") }}
            </v-btn>
          </v-card-text>
        </p>
        <p
          v-else-if="
            statuscleartrash === 'canceldelete' && countcancel > 0
          "
        >
          <span class="pl-3">{{ $t("trashPage.canceldelete") }}</span>
        </p>
      </v-card>
      <div
        class="mr-n2"
        v-if="statuscleartrash !== 'start' && calpercendelete !== 100"
      >
        <v-progress-linear
          :value="calpercenbackupanddelete"
          color="blue-grey"
          height="12"
          class="mx-0"
        >
          <strong>{{ calpercenbackupanddelete }}%</strong>
        </v-progress-linear>
      </div>
    </v-snackbar>
  </div>
  <div class="text-center" v-else>
    <v-snackbar v-model="shower" :timeout="-1" bottom>
      <div
        class="text-right mb-n2 mt-1"
        v-if="countdeleteerror > 0 || countcancel > 0"
      >
        <v-btn @click="$emit('closedialog')" icon x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card color="transparent" elevation="0" width="500" class="mt-3 pa-3">
        <p v-if="statuscleartrash === 'start'">
          <span class="pl-2">{{ $t("trashPage.preparedelete") }}</span>
          <v-btn
            style="margin-left:10px; margin-top:-4px;"
            class="float-right"
            small
            color="secondary"
            @click="$emit('cancelselectall')"
          >
            {{ $t("dragdrop.cancel") }}
          </v-btn>
        </p>
        <p v-else-if="statuscleartrash === 'cancelselect'">
          <span class="pl-2">{{ $t("trashPage.canceldelete") }}</span>
        </p>
        <p
          v-else-if="
            statuscleartrash === 'delete' &&
              calpercendelete === 100 &&
              countdeleteerror === 0
          "
        >
          <span class="pl-2">{{ $t("trashPage.deletesuccess") }}</span>
          <span class="float-right"
            ><v-icon color="green">mdi-check-bold</v-icon></span
          >
        </p>
        <p
          v-else-if="
            statuscleartrash === 'canceldelete' && countcancel > 0
          "
        >
          <span class="pl-2">{{ $t("trashPage.canceldelete") }}</span>
        </p>
        <p v-else-if="statuscleartrash === 'delete' && calpercendelete >= 0">
          <span class="pl-2" v-if="calpercendelete !== 100">{{
            $t("trashPage.deletingfilefolder")
          }}</span>
          <v-btn
            v-if="countdeleteerror > 0"
            style="margin-top:-4px;"
            small
            text
            @click="$emit('detaildelete')"
          >
            {{ $t("trashPage.cannotdelete") }}&nbsp; {{ countdeleteerror }}
            {{ $t("dragdrop.file") }}
          </v-btn>
          <span class="float-right"
            >{{ countdelete }} / {{ filedata.length }} ({{
              calpercendelete
            }}%)</span
          >
          <v-card-text
            class="text-right ma-0 pa-0 mb-n2"
            v-if="calpercendelete !== 100"
          >
            <br />
            {{ showdatadelete[countdelete - 1].file_name }}
            <v-btn
              style="margin-left:10px; margin-top:-4px;"
              small
              color="secondary"
              @click="$emit('cancelbackupanddelete')"
            >
              {{ $t("dragdrop.cancel") }}
            </v-btn>
          </v-card-text>
        </p>
      </v-card>
      <div
        class="mr-n2"
        v-if="
          statuscleartrash !== 'start' &&
            calpercendelete !== 100 &&
            countcancel === 0
        "
      >
        <v-progress-linear
          :value="calpercenbackupanddelete"
          color="blue-grey"
          height="12"
          class="mx-0"
        >
          <strong>{{ calpercenbackupanddelete }}%</strong>
        </v-progress-linear>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
export default {
  data: function() {
    return {};
  },
  comments: {},
  props: [
    "show",
    "countdelete",
    "statuscleartrash",
    "filedata",
    "showdatadelete",
    "countcancel",
    "countdeleteerror",
  ],
  data: function() {
    return {};
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
    calpercendelete() {
      let percen = Math.round((this.countdelete * 100) / this.filedata.length);
      if (percen === Infinity) {
        return 100;
      } else {
        return percen;
      }
    },
    calpercenbackupanddelete() {
      if (this.showdatadelete.length === 0) {
        return 0;
      } else {
        let percenbackup = this.showdatadelete[this.countdelete - 1]
          .backup_status.value;
        return percenbackup;
      }
    },
  },
  methods: {},
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
